import yall from 'yall-js';
import Splide from "@splidejs/splide";
import "../../../Y2024/AmazonVoucher/Css/tailwind.css";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/splide-core.min.css";
import "../../../Y2024/AmazonVoucher/Scss/main.scss";

export class General {

    constructor() {
        this.initializeLazyLoading();

    }

    private initializeLazyLoading: Function = () => {
        yall({
            observeChanges: true
        });
    }
}

export class Home {
    mediaQuery = window.matchMedia('(min-width: 1024px)');
    currentMatchQueries: any = {
        subscription: "",
        section1: "",
        section2: "",
        section3: "",
        section4: "",
        section5: "",
    };

    openFormButton: HTMLButtonElement = document.querySelector("#open-form");
    closeFormButton: HTMLButtonElement = document.querySelector("#close-form");
    subscriptionForm: HTMLDivElement = document.querySelector("#subscription-form");
    subscription: HTMLDivElement = document.querySelector("#subscription");
    navigation: HTMLElement = document.querySelector("nav");
    section1: HTMLDivElement = document.querySelector("#section-1");
    section2: HTMLDivElement = document.querySelector("#section-2");
    section3: HTMLDivElement = document.querySelector("#section-3");
    section4: HTMLDivElement = document.querySelector("#section-4");
    section5: HTMLDivElement = document.querySelector("#section-5");
    section6: HTMLDivElement = document.querySelector("#section-6");
    section7: HTMLDivElement = document.querySelector("#section-7");

    constructor(isSpecialPromotionCode: string) {
        this.initializeMatchMedia()
            .then(() => {
                this.initializeScrollingHref()
            })

        if(isSpecialPromotionCode ==="True"){
         setTimeout(()=>{   document.querySelector<HTMLAnchorElement>("#contact > a").click();},15000)
        }
    }

    private initializeScrollingHref: Function = () => {
        document.querySelectorAll("a[href*=\"#\"]:not([href=\"#\"])").forEach((element: HTMLAnchorElement) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                document.querySelector(element.hash).scrollIntoView(true)
            })
        })
    }

    private initializeMatchMedia(): Promise<boolean> {
        return Promise.all(
            [
                this.initializeMatchMediaSubscription(),
                this.initializeMatchMediaSection1(),
                this.initializeMatchMediaSection2(),
                this.initializeMatchMediaSection3(),
                this.initializeMatchMediaSection4(),
                this.initializeMatchMediaSection5(),
                this.initializeMatchMediaSection6(),
                this.initializeMatchMediaSection7(),
            ]).then(([]) => {
            return Promise.resolve(true)
        });
    }

    private initializeMatchMediaSubscription(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("subscription", e.matches ? null : this.initializeSubscription.bind(this)));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("subscription", e.matches ? null : this.initializeSubscription.bind(this)));
        }
        return this.handleLoadSection("subscription", this.mediaQuery.matches ? null : this.initializeSubscription.bind(this))
    }

    private initializeMatchMediaSection1(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section1", e.matches ? null : this.initializeSection1.bind(this)));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section1", e.matches ? null : this.initializeSection1.bind(this)));
        }
        return this.handleLoadSection("section1", this.mediaQuery.matches ? null : this.initializeSection1.bind(this));


    }

    private initializeMatchMediaSection2(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section2", null));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section1", null));
        }
        return this.handleLoadSection("section2", null);

    }

    private initializeMatchMediaSection3(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section3", null));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section3", null));
        }
        return this.handleLoadSection("section3", null)
    }

    private initializeMatchMediaSection4(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section4",
                this.mediaQuery.matches ? null : this.initializeSection4.bind(this)
            ));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section4", this.initializeSection4.bind(this)));
        }
        return this.handleLoadSection("section4", this.initializeSection4.bind(this));

    }

    private initializeMatchMediaSection5(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section5", e.matches ? null : this.initializeSection5.bind(this)));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section5", this.mediaQuery.matches ? null : this.initializeSection5.bind(this)));
        }
        return this.handleLoadSection("section5", this.mediaQuery.matches ? null : this.initializeSection5.bind(this));
    }

    private initializeMatchMediaSection6(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section6", null));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section6", null));
        }
        return this.handleLoadSection("section6", null)
    }

    private initializeMatchMediaSection7(): Promise<boolean> {
        if (navigator.vendor.match(/apple/i) !== null) {
            this.mediaQuery.addListener((e) => this.handleLoadSection("section7", null));
        } else {
            this.mediaQuery.addEventListener("change",
                (e) => this.handleLoadSection("section7", null));
        }
        return this.handleLoadSection("section7", null)
    }

    private handleLoadSection(sectionName: string, functionToExecute): Promise<boolean> {
        let designType = this.mediaQuery.matches ? "Desktop" : "Mobile";
        if (this.currentMatchQueries[sectionName] === designType) return;
        return fetch("/Assets/Y2024/AmazonVoucher/Templates/" + designType + "/" + sectionName + ".html")
            .then(response => response.text())
            .then(data => this[sectionName].innerHTML = data)
            .then(eval(functionToExecute))
            .then(() => {
                this.currentMatchQueries[sectionName] = designType;
                return Promise.resolve(true)
            })
    }

    //Mobile functions

    private initializeSubscription(): void {

        this.openFormButton.addEventListener("click", () => {
            document.documentElement.classList.add('overflow-y-hidden');
            this.navigation.classList.add("rtl");
            this.openFormButton.classList.add("hidden");
            this.subscriptionForm.classList.add("show");
            this.closeFormButton.classList.remove("hidden");

        })
        this.closeFormButton.addEventListener("click", () => {
            document.documentElement.classList.remove('overflow-y-hidden');
            this.closeFormButton.classList.add("hidden");
            this.navigation.classList.remove("rtl");
            this.subscriptionForm.classList.remove("show");
            this.openFormButton.classList.remove("hidden");
        })
    }

    private initializeSection1(): void {
        this.initizalizeCarousel("#slide-subscription", {
            pagination: false,
            padding: '1rem',
        })

    }

    private initializeSection4(): void {
        console.log(this.mediaQuery.matches);
        this.mediaQuery.matches === true ? this.section4.classList.add("section") : this.section4.classList.remove("section");

    }

    private initializeSection5(): void {
        this.initizalizeCarousel("#slide-testimonial", {
            pagination: false,
            padding: '1rem',
            lazyLoad: 'nearby'
        })
    }

    private initizalizeCarousel(target: string, options): void {
        new Splide(target, options).mount();
    };
}